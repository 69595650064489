
import { Convert } from '@itus/ui.common'

export class TaxonomyModel {
  constructor(data) {
    data = data || {}
    this.id = Convert.toString(data.id, null)
    this.category = Convert.toString(data.category, null)
    this.class = Convert.toString(data.class, null)
    this.type = Convert.toString(data.type, null)
    this.score = Convert.toInteger(data.score, 0)
    this.distribution = Convert.toString(data.distribution, null)
    this.mtbf = Convert.toString(data.mtbf, null)
    this.shape = Convert.toFloat(data.shape, null)
  }
}

export default { TaxonomyModel }