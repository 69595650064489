import axios from 'axios'
import { debounce, isEqual } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { Time } from '@itus/ui.common'
import { AssetModel } from '@/views/apps/analyses/asset-model'
import ScenarioModel from '@/views/apps/analyses/scenario-model'
import { Weibull, RiskAnalysisModel } from "@itus/ui.risk"
import { Duration } from 'luxon'
import { calculateScenario } from '@/views/apps/analyses/scenario-calculator'
import ScenarioConfigurationModel from '../../views/apps/analyses/scenario-configuration-model'
import ScenarioAssetModel from '../../views/apps/analyses/scenario-asset-model'
import { buildApiUrl } from '@/components/api-url-builder'

async function apiAddAnalysis(commit, analysis) {
  const promise = axios.post(buildApiUrl('/api/analyses'), analysis)
    .then(() => commit('setAnalysisSaved', analysis))
    .then(() => commit('setAnalysisValidationMessage', []))
    .catch(error => {
      if (error && error.response && error.response.status === 409 && error.response.data)
        commit('setAnalysisValidationMessage', [error.response.data.error ?? 'Name already exists.'])
    })
  commit('setAnalysisSavePromise', promise)
  await promise
}

async function apiEditAnalysis(commit, analysis) {
  const promise = axios.put(buildApiUrl(`/api/analyses/${analysis.analysis}`), analysis)
  commit('setAnalysisSavePromise', promise)
  await promise
  commit('setAnalysisSaved', analysis)
}

async function apiAddAsset(commit, dispatch, asset) {
  try {
    dispatch('calculateAssets', state.assets)
    const promise = axios.post(buildApiUrl('/api/assets'), asset)
    commit('setAssetSavePromise', promise)
    await promise
    commit('setAssetValidationMessage', null)
    commit('setAssetSaved', asset)
    dispatch('calculateScenarios')
  } catch (error) {
    if (error && error.response && error.response.status === 409 && error.response.data) {
      commit('setAssetValidationMessage', error.response.data.error ?? 'Name already exists.')
    }
  } finally {
    commit('setAssetSavePromise', null)
  }
}

async function apiEditAsset(commit, dispatch, asset) {
  try {
    dispatch('calculateAssets', state.assets)
    const promise = axios.put(buildApiUrl(`/api/assets/${asset.assetId}`), asset)
    commit('setAssetSavePromise', promise)
    await promise
    commit('setAssetValidationMessage', null)
    commit('setAssetSaved', asset)
    dispatch('calculateScenarios')
  } catch (error) {
    if (error && error.response && error.response.status === 409 && error.response.data) {
      commit('setAssetValidationMessage', error.response.data.error ?? 'Name already exists.')
    }
  } finally {
    commit('setAssetSavePromise', null)
  }
}

async function apiEditScenario(commit, dispatch, scenario) {
  //  strip out assets array from the config structure
  const trimmedConfig = new ScenarioConfigurationModel({
    startDate: scenario.scenarioConfiguration.startDate,
    missionTime: scenario.scenarioConfiguration.missionTime,
    endDate: scenario.scenarioConfiguration.endDate,
    probabilityThreshold: scenario.scenarioConfiguration.probabilityThreshold,
    initialMTBF: scenario.scenarioConfiguration.initialMTBF
  })
  //  strip out original config structure
  const trimmedScenario = new ScenarioModel(scenario, trimmedConfig)

  const promise = axios.put(buildApiUrl(`/api/analyses/${state.analysisId}/scenarios/${scenario.scenarioId}/configuration`), trimmedScenario)
  commit('setScenarioSavePromise', promise)
  await promise
}

async function apiEditScenarioAsset(commit, dispatch, asset) {
  const promise = axios.put(buildApiUrl(`/api/analyses/${state.analysisId}/scenarios/${state.scenario.scenarioId}/asset`), asset)
  commit('setScenarioSavePromise', promise)
  await promise
}

const debouncedAddAnalysis = debounce(apiAddAnalysis, 250, { maxWait: 1000 })
const debouncedEditAnalysis = debounce(apiEditAnalysis, 250, { maxWait: 1000 })
const debouncedAddAsset = debounce(apiAddAsset, 250, { maxWait: 1000 })
const debouncedEditAsset = debounce(apiEditAsset, 250, { maxWait: 1000 })
const debouncedEditScenario = debounce(apiEditScenario, 250, { maxWait: 1000 })
const debouncedEditScenarioAsset = debounce(apiEditScenarioAsset, 250, { maxWait: 1000 })

const getDefaultState = () => {
  return {
    filters: [],
    analyses: [],
    analysesPromise: null,
    analysesLoading: false,
    analysis: null,
    analysisId: null,
    analysisIsNew: false,
    originalAnalysisName: null,
    analysisPromise: null,
    analysisSavePromise: null,
    analysisValidationMessage: null,
    analysisCreate: null,
    analysisNames: [],
    assets: null,
    newAsset: null,
    assetsPromise: null,
    assetSavePromise: null,
    assetValidationMessage: null,
    scenariosPromise: null,
    scenarioSavePromise: null,
    scenarios: null,
    scenarioInvalidationConfirmed: false,
    scenario: null
  }
}

const pageSize = 100

const state = getDefaultState()

const getters = {
  analysisLimit: () => 20,
  assetsPerAnalysis: () => 1000,
  assetIsNew: (s) => s.newAsset !== null
}

// actions
const actions = {
  clearAnalyses({ commit }) {
    commit('setFilters', null)
    commit('setAnalyses', null)
    commit('setAnalysesPromise', null)
  },
  clearNewAsset({commit}){
    commit('clearNewAssetIfExists')
  },
  loadAnalysisNames({commit}) {
    return axios.get(buildApiUrl('/api/analyses/names')).then((response) => {
      commit('setAnalysisNames', response?.data)
    }).catch(() => {
      commit('setAnalysisNames', [])
    })
  },
  loadAnalyses({ commit, dispatch }, options) {
    const filters = options.filters || state.filters
    if (options.force || !isEqual(options.filters, state.filters)) {
      commit('setAnalysesPromise', null)
    }
    if (state.analysesPromise) {
      return state.analysesPromise
    }

    commit('setAnalysesLoading', true)
    dispatch('clearAnalyses')
    let promise = new Promise((resolve) => {
      commit('setFilters', filters)
      axios.post(buildApiUrl(`/api/analyses/filters?page-size=${pageSize}`), { filters: filters })
        .then(response => {
          let results = response?.data?.results && response.data.results.length > 0 ? response.data.results.map(x => new RiskAnalysisModel(x)) : []
          commit('setAnalyses', results)
        })
        .finally(() => {
          commit('setAnalysesLoading', false)
          resolve()
        })
    })

    commit('setAnalysesPromise', promise)
    return promise
  },
  getAnalysesCount(){
    return new Promise((resolve) => {
    axios.post(buildApiUrl('/api/analyses/filters'), {})
        .then(response => {
          let results = response?.data?.results && response.data.results.length > 0 ? response.data.results.length : 0
          resolve(results)
        })
    } )
  },
  async loadAnalysis({ commit, dispatch }, params) {
    if (state.analysisPromise && state.analysisId == params.id) {
      return state.analysisPromise
    }

    commit('clearAssets')
    commit('clearScenarios')
    if (params.id.toLowerCase() === 'new') {
      let newId = uuidv4()
      commit('setAnalysisId', newId)
      commit('setAnalysisIsNew', true)
      commit('loadAnalysis', new RiskAnalysisModel({ analysis: newId, startDate: Time.nowDate() }))
      return
    }
    commit('setAnalysisIsNew', false)
    commit('setAnalysisId', params.id)

    if(state.analysis && state.analysis.analysis === params.id && !params.force)
      return

    let promise = axios.get(buildApiUrl(`/api/analyses/${params.id}`))
    commit('setAnalysisPromise', promise)
    let response = await promise
    commit('loadAnalysis', new RiskAnalysisModel(response.data))

    await dispatch('loadScenarios')
  },
  async saveAnalysis({ commit, dispatch }, analysis) {
    if (!(analysis instanceof RiskAnalysisModel)) {
      throw new Error('Must be type AnalysisModel.')
    }
    if (!state.analysisId || state.analysisId !== analysis.analysis) {
      throw new Error('loadAnalysis must be called before saving the analysis.')
    }

    await state.analysisSavePromise

    let recalcAssets = false
    if (state.analysisIsNew || !state.analysis || !state.assets || (state.analysis && (state.analysis.startDate !== analysis.startDate || state.analysis.missionTime !== analysis.missionTime || state.analysis.endDate !== analysis.endDate || state.analysis.probabilityThreshold !== analysis.probabilityThreshold)))
      recalcAssets = true

    commit('setAnalysis', new RiskAnalysisModel(analysis))
    if (recalcAssets) {
      dispatch('calculateAssets', state.assets)
      dispatch('calculateScenarios')
    }

    if (state.analysisIsNew) {
      debouncedAddAnalysis(commit, analysis)
    } else {
      debouncedEditAnalysis(commit, analysis)
    }
  },
  async deleteAnalysis({ commit }, analysisId) {
    let response = await axios.delete(buildApiUrl(`/api/analyses/${analysisId}`))
    commit('removeAnalysis', analysisId)

    if (analysisId === state.analysisId)
      commit('clearAnalysis')

    return response
  },
  async loadAssets({ commit }, params) {
    if (!state.analysisId) {
      throw new Error('loadAnalysis must be called before loading assets so we have the analysis id')
    }

    params = params || {}

    //  We return the stored promise so that multiple calls to this will only do one round trip to the server
    if (state.assetsPromise && !params.force) {
      return state.assetsPromise
    }

    if(state.analysisIsNew) {
      commit('setAssetsPromise', Promise.resolve([]))
      commit('setAssets', [])
    } else {
      let promise = axios.post(buildApiUrl('/api/assets/filters?page-size=1000'), { filters: [{ name: "analysisId", op: "=", value: state.analysisId }] })
      commit('setAssetsPromise', promise)

      let response = await promise
      commit('setAssets', response.data.results.map(x => new AssetModel(x)))
    }
  },
  async createAsset({ commit }) {
    await state.assetSavePromise
    commit('createAsset')
  },
  async saveAsset({ commit, dispatch }, assetParams) {
    let asset = assetParams.asset
    if (!(asset instanceof AssetModel)) {
      throw new Error('Must be type AssetModel.')
    }
    if (!state.analysisId || state.analysisId !== asset.analysisId) {
      throw new Error('loadAnalysis must be called before adding an asset.')
    }
    if (!state.assets) {
      throw new Error('loadAssets must be called before adding an asset.')
    }
    if (state.newAsset && state.newAsset.assetId !== asset.assetId) {
      throw new Error('newAsset does not match asset being saved')
    }

    await state.assetSavePromise

    const distributionCalc = new Weibull('local', state.analysis.startDate, state.analysis.missionTime, { type: 'cdf', shape: 1, scale: asset.probabilityFailureDistributionScale })
    const answer = distributionCalc.calculateRiskAtMissionDate()
    asset.probabilityValue = (answer === null || answer === undefined || isNaN(answer)) ? null : Math.trunc(answer * 100)

    if (state.newAsset) {
      commit('setAssetValidationMessage', null)
      if(assetParams.immediate)
        apiAddAsset(commit, dispatch, asset)
      else
        debouncedAddAsset(commit, dispatch, asset)
    } else {
      commit('updateAsset', asset)
      if(assetParams.immediate)
        apiEditAsset(commit, dispatch, asset)
      else
        debouncedEditAsset(commit, dispatch, asset)
    }
  },
  async deleteAsset({ commit, dispatch }, assetId) {
    if (!state.analysisId)
      throw new Error('loadAnalysis must be called before deleting an asset.')

    if (!state.assets)
      throw new Error('loadAssets must be called before deleting an asset.')

    let asset = state.assets.find(x => x.assetId === assetId)
    if (!asset) {
      throw new Error(`Could not find an asset in the store with id ${assetId}.`)
    }

    let response = await axios.delete(buildApiUrl(`/api/assets/${assetId}`))
    commit('removeAsset', assetId)
    dispatch('calculateAssets', state.assets)
    dispatch('calculateScenarios')
    return response
  },
  async calculateAssets({ rootState }, assets) {
    if (!rootState.taxonomy || !rootState.taxonomy.taxonomyMap) {
      throw new Error('loadTaxonomy must be called before assets can be calculated.')
    }

    await state.analysisPromise

    let analysisValidForCalculations = !!state.analysis && !!state.analysis.startDate && !!state.analysis.missionTime && !!state.analysis.endDate

    for (let asset of assets) {
      const assetMtbf = asset.probabilityFailureDistributionScale
      const assetMtbfDuration = assetMtbf ? Duration.fromISO(assetMtbf) : null
      const libraryAssetClass = rootState.taxonomy.taxonomyMap && asset.assetClass && rootState.taxonomy.taxonomyMap[asset.assetClass] ? rootState.taxonomy.taxonomyMap[asset.assetClass] : null
      const libraryMtbf = (libraryAssetClass && libraryAssetClass.mtbf) ? Duration.fromISO(libraryAssetClass.mtbf) : null
      asset.comparativeMtbf = (assetMtbfDuration && libraryMtbf) ? assetMtbfDuration.minus(libraryMtbf).normalize().toISO() : null

      // can we calculate probabilty and risk?
      if (!analysisValidForCalculations || !assetMtbf) {
        continue
      }

      const distributionCalc = new Weibull('local', state.analysis.startDate, state.analysis.missionTime, { type: 'cdf', shape: 1, scale: assetMtbf })
      const answer = distributionCalc.calculateRiskAtMissionDate()
      asset.probabilityValue = (answer === null || answer === undefined || isNaN(answer)) ? null : Math.trunc(answer * 100)
    }
  },
  async loadScenarios({ commit }) {
    if (!state.analysisId)
      throw new Error('loadAnalysis must be called before loading scenarios so we have the analysis id')

    //  We return the stored promise so that multiple calls to this will only do one round trip to the server
    if (state.scenariosPromise)
      return state.scenariosPromise

    if(state.analysisIsNew) {
      commit('setScenariosPromise', Promise.resolve([]))
      commit('setScenarios', [])
    } else {
      let promise = axios.get(buildApiUrl(`/api/analyses/${state.analysisId}/scenarios`))
      commit('setScenariosPromise', promise)

      let response = await promise
      commit('setScenarios', response?.data?.map(x => new ScenarioModel(x)) ?? [])
    }
  },
  loadScenario({ commit }, id) {
    if(!state.scenarios)
      throw new Error('loadScenarios must be called before loading an individual scenario')

    if(state.scenarios.length == 0 || !state.scenarios.find(x => x.scenarioId === id))
      throw new Error('Scenario not found.')

    commit('setScenario', state.scenarios.find(x => x.scenarioId === id))
  },
  async addScenario({ commit, dispatch }, scenario) {
    if (!(scenario instanceof ScenarioModel))
      throw new Error('Must be type ScenarioModel.')

    if (!state.analysisId)
      throw new Error('loadAnalysis must be called before adding a scenario.')

    if (!state.scenarios)
      throw new Error('loadScenarios must be called before adding an scenario.')

    let result = await axios.post(buildApiUrl(`/api/analyses/${state.analysisId}/scenarios`), scenario)
    commit('addScenario', scenario)
    await dispatch('calculateScenarios')
    return result
  },
  async editScenarioHeader({ commit, dispatch }, scenario) {
    if (!(scenario instanceof ScenarioModel))
      throw new Error('Must be type ScenarioModel.')

    if (!state.analysisId)
      throw new Error('loadAnalysis must be called before editting a scenario.')

    if (!state.scenario || !state.scenario.scenarioId || state.scenario.scenarioId !== scenario.scenarioId)
      throw new Error('loadScenario must be called before editting a scenario.')

    await state.scenarioSavePromise

    const recalcScenario = (state.scenario &&
      (state.scenario.scenarioConfiguration.startDate !== scenario.scenarioConfiguration.startDate ||
        state.scenario.scenarioConfiguration.missionTime !== scenario.scenarioConfiguration.missionTime ||
        state.scenario.scenarioConfiguration.endDate !== scenario.scenarioConfiguration.endDate))

    commit('setScenarioHeader', scenario)

    if (recalcScenario) {
      dispatch('calculateScenario', state.scenario)
    }

    if (!scenario.scenarioConfiguration.startDate || !scenario.scenarioConfiguration.missionTime || !scenario.scenarioConfiguration.endDate) {
      //  If these critical values do not exist then we are choosing not to save.
      return
    }

    debouncedEditScenario(commit, dispatch, scenario)
  },
  async editScenarioAsset({ commit, dispatch }, asset) {
    if (!(asset instanceof AssetModel) && !(asset instanceof ScenarioAssetModel))
      throw new Error('Must be type AssetModel or ScenarioAssetModel.')

    if (!state.analysisId)
      throw new Error('loadAnalysis must be called before editting a scenario.')

    if (!state.scenario || !state.scenario.scenarioId)
      throw new Error('loadScenario must be called before editting a scenario.')

    await state.scenarioSavePromise

    commit('updateScenarioAsset', asset)
    dispatch('calculateScenario', JSON.parse(JSON.stringify(state.scenario)))

    debouncedEditScenarioAsset(commit, dispatch, asset)
  },

  async deleteScenario({ commit }, scenarioId) {
    if (!state.analysisId)
      throw new Error('loadAnalysis must be called before deleting a scenario.')

    if (!state.scenarios)
      throw new Error('loadScenarios must be called before deleting a scenario.')

    let scenario = state.scenarios.find(x => x.scenarioId === scenarioId)
    if (!scenario)
      throw new Error(`Could not find a scenario in the store with id ${scenarioId}.`)

    let result = await axios.delete(buildApiUrl(`/api/analyses/${state.analysisId}/scenarios/${scenarioId}`))
    commit('removeScenario', scenarioId)
    return result
  },
  async calculateScenarios({ commit, rootState }) {
    if (!state.analysisId)
      throw new Error('loadAnalysis must be called before calculating a scenario.')

    if (!rootState.taxonomy || !rootState.taxonomy.taxonomyMap)
      throw new Error('loadTaxonomy must be called before calculating a scenario.')

    if (!state.assets)
      throw new Error('loadAssets must be called before calculating a scenario.')

    if(!state.scenarios || state.scenarios.length == 0) {
      commit('setScenarios', [])
      return
    }

    await state.analysisPromise

    let analysisOpportunity = parseInt(state.assets.filter(x => x.probabilityValue >= state.analysis.probabilityThreshold).map(y => parseInt(y.consequenceValue)).reduce(((pv, cv) => pv + cv), 0))
    if(isNaN(analysisOpportunity))
      analysisOpportunity = 0

    let analysisRisk = parseInt(state.assets.map(y => y.risk ? parseInt(y.risk) : 0).reduce(((pv, cv) => pv + cv), 0))
    if(isNaN(analysisRisk))
      analysisRisk = 0

    let clonedScenarios = JSON.parse(JSON.stringify(state.scenarios))
    let calculatedScenarios = []
    for(let scenario of clonedScenarios) {
      calculatedScenarios.push(calculateScenario(scenario, state.analysis, state.assets, rootState.taxonomy.taxonomyMap, analysisOpportunity, analysisRisk))
    }

    commit('setScenarios', calculatedScenarios)
  },
  calculateScenario({ commit, rootState }, scenario) {
    if(!scenario)
      throw new Error('Scenario is required')

    if (!state.analysisId)
      throw new Error('loadAnalysis must be called before calculating a scenario.')

    if(!state.scenarios || state.scenarios.length == 0)
      throw new Error('loadScenarios must be called before calculating a scenario.')

    if(!state.scenario)
      throw new Error('loadScenario must be called before calculating a scenario.')

    if (!rootState.taxonomy || !rootState.taxonomy.taxonomyMap)
      throw new Error('loadTaxonomy must be called before calculating a scenario.')

    if (!state.assets)
      throw new Error('loadAssets must be called before calculating a scenario.')

    scenario.valid = true
    let analysisOpportunity = parseInt(state.assets.filter(x => x.probabilityValue >= state.analysis.probabilityThreshold).map(y => parseInt(y.consequenceValue)).reduce(((pv, cv) => pv + cv), 0))
    if(isNaN(analysisOpportunity))
      analysisOpportunity = 0

    let analysisRisk = parseInt(state.assets.map(y => y.risk ? parseInt(y.risk) : 0).reduce(((pv, cv) => pv + cv), 0))
    if(isNaN(analysisRisk))
      analysisRisk = 0

    let reCalcedScenario = calculateScenario(scenario, state.analysis, state.assets, rootState.taxonomy.taxonomyMap, analysisOpportunity, analysisRisk)
    commit('updateScenario', reCalcedScenario)
  },
  setScenarioInvalidationConfirmed({ commit }, val) {
    commit('setScenarioInvalidationConfirmed', val)
  },
  resetState({ commit }) {
    commit('resetState')
  }
}

// mutations
const mutations = {
  resetState() {
    //  Reset the state of the store - merge so that we don't lose observers
    Object.assign(state, getDefaultState())
  },
  setFilters(s, filters) {
    s.filters = filters
  },
  setAnalyses(s, analyses) {
    s.analyses = analyses
  },
  setAnalysesPromise(s, analysesPromise) {
    s.analysesPromise = analysesPromise
  },
  setAnalysesLoading(s, analysesLoading) {
    s.analysesLoading = analysesLoading
  },
  setAnalysisId(s, analysisId) {
    s.analysisId = analysisId
  },
  setAnalysisPromise(s, analysisPromise) {
    s.analysisPromise = analysisPromise
  },
  setAnalysisSavePromise(s, analysisSavePromise) {
    s.analysisSavePromise = analysisSavePromise
  },
  setAnalysisIsNew(s, val) {
    s.analysisIsNew = val
  },
  setAnalysisValidationMessage(s, message) {
    s.analysisValidationMessage = message
  },
  setScenarioInvalidationConfirmed(s, val) {
    s.scenarioInvalidationConfirmed = val
  },
  setAnalysis(s, analysis) {
    if (s.analyses) {
      let existingAnalysis = s.analyses.find(x => x.analysis === analysis.analysis)
      if (existingAnalysis) {
        Object.assign(existingAnalysis, analysis)
      }
    }
    s.analysis = analysis
    s.analysisValidationMessage = null
  },
  setAnalysisSaved(s, analysis) {
    if (s.analyses && s.analysisIsNew) {
      s.analyses.push(analysis)
    }
    s.analysisIsNew = false
    s.originalAnalysisName = analysis.analysisName
  },
  loadAnalysis(s, analysis) {
    s.analysis = analysis
    s.originalAnalysisName = analysis.analysisName
    s.scenarioInvalidationConfirmed = false
  },
  removeAnalysis(s, id) {
    let indexToRemove = s.analyses.findIndex(x => x.analysis === id)
    s.analyses.splice(indexToRemove, 1)
  },
  clearAnalysis(s) {
    s.analysisPromise = null
    s.analysis = null
    s.analysisId = null
    s.analysisIsNew = false
    s.originalAnalysisName = null
    s.assetsPromise = null
    s.assets = null
    s.scenarioInvalidationConfirmed = false
  },
  clearAssets(s) {
    s.assetsPromise = null
    s.assets = null
  },
  setAssetsPromise(s, assetsPromise) {
    s.assetsPromise = assetsPromise
  },
  setAnalysisNames(s, names) {
    s.analysisNames = names
  },
  createAsset(s) {
    if (!state.analysisId) {
      throw new Error('loadAnalysis must be called before adding an asset.')
    }
    if (!state.assets) {
      throw new Error('loadAssets must be called before adding an asset.')
    }
    s.newAsset = new AssetModel({ assetId: uuidv4(), analysisId: s.analysisId })
  },
  setAssets(s, assets) {
    s.assets = assets
  },
  updateAsset(s, assetCopy) {
    let assetIndex = s.assets.findIndex(x => x.assetId === assetCopy.assetId)
    s.assets.splice(assetIndex, 1, assetCopy)
    s.assetValidationMessage = null
  },
  setAssetSavePromise(s, assetSavePromise) {
    s.assetSavePromise = assetSavePromise
  },
  setAssetValidationMessage(s, message) {
    s.assetValidationMessage = message
  },
  setAssetSaved(s, asset) {
    if (s.assets && s.newAsset && s.newAsset.assetId === asset.assetId) {
      s.assets.push(asset)
    }
    s.newAsset = null
  },
  clearNewAssetIfExists(s){
    s.newAsset = null
  },
  removeAsset(s, id) {
    let indexToRemove = s.assets.findIndex(x => x.assetId === id)
    s.assets.splice(indexToRemove, 1)
  },
  setScenariosPromise(s, scenariosPromise) {
    s.scenariosPromise = scenariosPromise
  },
  setScenarioSavePromise(s, scenarioSavePromise) {
    s.scenarioSavePromise = scenarioSavePromise
  },
  setScenarios(s, scenarios) {
    s.scenarios = scenarios
  },
  setScenario(s, scenario) {
    s.scenario = scenario
  },
  setScenarioHeader(s, scenario) {
    s.scenario.scenarioName = scenario.scenarioName
    s.scenario.description = scenario.description
    s.scenario.scenarioConfiguration.startDate = scenario.scenarioConfiguration.startDate
    s.scenario.scenarioConfiguration.missionTime = scenario.scenarioConfiguration.missionTime
    s.scenario.scenarioConfiguration.endDate = scenario.scenarioConfiguration.endDate

    let scenarioIndex = s.scenarios.findIndex(x => x.scenarioId === s.scenario.scenarioId)
    s.scenarios[scenarioIndex].scenarioName = scenario.scenarioName
    s.scenarios[scenarioIndex].description = scenario.description
    s.scenarios[scenarioIndex].scenarioConfiguration.startDate = scenario.scenarioConfiguration.startDate
    s.scenarios[scenarioIndex].scenarioConfiguration.missionTime = scenario.scenarioConfiguration.missionTime
    s.scenarios[scenarioIndex].scenarioConfiguration.endDate = scenario.scenarioConfiguration.endDate

  },
  addScenario(s, scenario) {
    s.scenarios.push(scenario)
  },
  updateScenario(s, scenario) {
    let scenarioIndex = s.scenarios.findIndex(x => x.scenarioId === scenario.scenarioId)
    s.scenarios.splice(scenarioIndex, 1, scenario)
    s.scenario = scenario
  },
  removeScenario(s, id) {
    let indexToRemove = s.scenarios.findIndex(x => x.scenarioId === id)
    s.scenarios.splice(indexToRemove, 1)
  },
  clearScenarios(s) {
    s.scenariosPromise = null
    s.scenarios = null
    s.scenario = null
  },
  updateScenarioAsset(s, asset) {
    let scenarioIndex = s.scenarios.findIndex(x => x.scenarioId === s.scenario.scenarioId)
    let assetIndex = s.scenarios[scenarioIndex].scenarioConfiguration.assets.findIndex(x => x.assetId === asset.assetId)
    s.scenarios[scenarioIndex].scenarioConfiguration.assets.splice(assetIndex, 1, asset)
    s.scenario = s.scenarios[scenarioIndex]
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}