import { v4 as uuidv4 } from 'uuid'
import { Convert } from '@itus/ui.common'
import { Duration } from 'luxon'

export class AssetModel {
  constructor(data) {
    data = data || {}
    this.analysisId = Convert.toString(data.analysisId, null)
    this.assetId = Convert.toString(data.assetId, null)
    this.assetName = Convert.toString(data.assetName, null)
    this.description = Convert.toString(data.description, null)
    this.assetClass = Convert.toString(data.assetClass, null)
    this.assetClassLabel = Convert.toString(data.assetClassLabel, null)
    this.probabilityValue = Convert.toInteger(data.probabilityValue, null)
    this.probabilityFailureDistributionScale = Convert.toString(data.probabilityFailureDistributionScale, null)

    this.consequenceValue = Convert.toInteger(data.consequenceValue, null)
    if(typeof data.improve === 'boolean')
      this.improve = Convert.toBoolean(data.improve, false) 
    else 
      this.improve = data.improve === 'true'
      
    this.improveDescription = Convert.toString(data.improveDescription, null)
    this.library = Convert.toBoolean(data.library, null)

    //  calculated - not exported for JSON.stringify (purposefully left out of toJSON)
    this.comparativeMtbf = null
  }

  //  calculated - not exported for JSON.stringify (purposefully left out of toJSON)
  get risk() {
    if (this.consequenceValue === null || this.consequenceValue === undefined || this.probabilityValue === null || this.probabilityValue === undefined) {
      return null
    }
    return this.consequenceValue < 0 ? 0 : (this.probabilityValue / 100) * this.consequenceValue
  }

  //  calculated - not exported for JSON.stringify (purposefully left out of toJSON)
  get opportunity(){
    return this.probabilityValue 
  }

  toJSON() {
    return {
      analysisId: this.analysisId,
      assetId: this.assetId,
      assetName: this.assetName,
      description: this.description,
      assetClass: this.assetClass,
      assetClassLabel: this.assetClassLabel,
      probabilityValue: this.probabilityValue,
      probabilityFailureDistributionScale: this.probabilityFailureDistributionScale,
      consequenceValue: this.consequenceValue,
      improve: this.improve,
      improveDescription: this.improveDescription,
      library: this.library
    }
  }

  static fromRow(row) {
    row = row || {}
    const data = {
      assetId: uuidv4(),
      assetName: row.asset_name,
      description: row.asset_description,
      probabilityFailureDistributionScale: row.probability_mtbf !== null && row.probability_mtbf !== undefined && !isNaN(row.probability_mtbf) ? `P${parseInt(row.probability_mtbf)}D` : null,
      consequenceValue: row.consequence_value
    }
    if (data.assetName && data.assetName.length > 100) {
      throw new Error('Asset names cannot exceed 100 characters.')
    }
    if (data.probabilityFailureDistributionScale && Duration.fromISO(data.probabilityFailureDistributionScale).toMillis() < 0) {
      throw new Error('Chraracteristic life values cannot be negative.')
    }
    if (data.consequenceValue !== undefined && data.consequenceValue !== null && data.consequenceValue < 0) {
      throw new Error('Consequence values cannot be negative.')
    }

    return new AssetModel(data)
  }
}

export default { AssetModel }