import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store'
import vuetify from './plugins/vuetify'
import VuePlyr from 'vue-plyr'

import 'vue-plyr/dist/vue-plyr.css'

import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@fortawesome/fontawesome-pro/css/all.css'
import '@/assets/itus.css'
import '@/assets/itus.scss'

Vue.config.productionTip = false

Vue.use(VuePlyr, { plyr: {} })

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
