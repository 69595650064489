<template>
  <v-dialog
    persistent
    v-model="dialog"
    max-width="600"
  >
    <v-card>
      <AlertBar
        v-model="message.show"
        :type="message.type"
        :message="message.text"
        :detail="message.detail"
      ></AlertBar>
      <v-container>
        <template v-if="step === 'info'">
          <v-row class="justify-center align-center">
            <v-col cols="12" class="mt-4">
              <div class="text-center text-h6 font-weight-bold">
                You are currently using HxGN Asset Risk Analyzer.
              </div>              
              <div class="text-center text-subtitle-1 mt-2">
                <v-icon color="accent" left>fa-solid fa-circle-arrow-up</v-icon>
                Upgrade to HxGN APM to mitigate failure risk with Asset Twins
              </div>
            </v-col>
          </v-row>
          <v-row class="justify-center align-center">
            <v-col cols="12" sm="8" class="text-center">
              <ProductCard
                :product="products[0]"
                @product-action="contactUs"
              >
              </ProductCard>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn text @click="cancel">Cancel</v-btn>
            </v-col>
          </v-row>
        </template>        
      </v-container>
    </v-card>
    <Busy :value="loading"></Busy>
  </v-dialog>
</template>
<script>
import { Busy, ExceptionMessage, AlertBar } from '@itus/ui.common'
import ProductCard from '@/components/registration/ProductCard'
import Products from '../assets/products.json'
import { mapState } from 'vuex'
import { ContactUs } from '@/components/contact-us'

export default {
  name: 'UpgradePremiumDialog',
  data() {
    return {
      dialog: false,
      resolve: null,
      loading: false,
      products: Products.products,
      inputIsValid: false,
      message: {show:false},
      step: 'info',
    }
  },
  components: {
    Busy,
    ProductCard,
    AlertBar
  },
  computed: {
     ...mapState({
      user: state => state.user
    }),
    fullName() {
      if (!this.user) {
        return null
      }
      return `${this.user.firstName} ${this.user.lastName}`
    }
  },
  methods: {
    show(contactBaseUrl) {
      if(contactBaseUrl)
        this.contactBaseUrl = contactBaseUrl

      this.message.show = false
      this.step = 'info'
      this.dialog = true

      return new Promise((resolve) => {
        this.resolve = resolve
      })
    },
    showException(err, defaultMsg) {
      this.message = ExceptionMessage.generateMessage(err, defaultMsg)
    },
    inputChanged(args){
      if(args.error && this.loading)
        this.loading = false

      this.inputIsValid = args.complete
    },
    contactUs() {
      new ContactUs().go(this.user)
    },
    upgradePremium(){
      this.dialog = false
      this.resolve('upgrade')
    },
    cancel() {
      this.dialog = false
      this.resolve('cancel')
    }
  }
}
</script>