import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '@fortawesome/fontawesome-pro/css/all.css';
import '@mdi/font/css/materialdesignicons.css' 
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'vuetify/dist/vuetify.min.css'
import minifyTheme from 'minify-css-string'
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
import VueGtag from "vue-gtag"
import gtag from "./gtag.json"
import 'typeface-roboto'
import 'material-icons'

Vue.use(Vuetify);
Vue.use(VuePlyr, {
  plyr: {}
})
Vue.use(VueGtag, {
  config: { id: gtag.id }
})

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },  
  theme: {
    dark: false,
    options: {
      customProperties: true,
      minifyTheme,
      themeCache: {
        get: () => localStorage.getItem('theme-v2'),
        set: (_, value) => localStorage.setItem('theme-v2', value)
      }
    },
    themes: {
      light: {    
        primary: "#1B618D",
        secondary: "#4CAF50",
        accent: "#7bc3ec",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107"
      },
      dark: {    
        primary: "#1B618D",
        secondary: "#4CAF50",
        accent: "#7bc3ec",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107"
      }      
    }    
  }
});
