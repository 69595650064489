class ContactUs {
  static BASE_URL = 'https://www.itusdigital.com/upgrade-to-itus-apm?utm_source=AssetRiskAnalyzer&utm_medium=Freemium&utm_campaign=ARA_Menu_Upgrade'

  constructor() {
    // nothing yet
  }

  go(user, baseUrl) {
    window.open(`${baseUrl ?? ContactUs.BASE_URL}&email=${user.email}&firstname=${user.firstName}&lastname=${user.lastName}`)
  }
}

export { ContactUs }