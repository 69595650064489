import Vue from 'vue'
import VueRouter from 'vue-router'
const { isNavigationFailure, NavigationFailureType } = VueRouter

import store from '@/store'
import buffer from 'buffer'
const Buffer = buffer.Buffer
Vue.use(VueRouter)

const appName = 'HxGN ARA'

const router = new VueRouter({
  store: store,
  mode: 'history',
  base: null,
  routes: [            
    {
      path: '/',
      component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
      meta: {
        requiresAuth : true,
        title: 'Home'
      }
    },  
    {
      path: '/home',
      name: 'home',
      component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
      meta: {
        requiresAuth : true,
        title: 'Home'
      }
    },
    {
      path: '/get-started',
      name: 'getStarted',
      component: () => import(/* webpackChunkName: "home" */ '@/views/Signin.vue'),
      meta: {
        redirectIfSignedIn: true,
        requiresAuth: false,
        title: 'Get Started'
      }
    },
    {
      path: '/activate',
      name: 'activate',
      component: () => import(/* webpackChunkName: "home" */ '@/views/Activate.vue'),
      meta: {
        redirectIfSignedIn: true,
        requiresAuth: false,
        title: 'Activate'
      }
    },    
    {
      path: '/signin',
      name: 'signin',
      component: () => import(/* webpackChunkName: "home" */ '@/views/Signin.vue'),
      meta: {
        redirectIfSignedIn: true,
        requiresAuth: false,
        title: 'Sign in'
      }
    },
    {
      path: '/signout',
      name: 'signout',
      component: () => import(/* webpackChunkName: "home" */ '@/views/Signout.vue'),
      meta: {
        requiresAuth: false,
        title: 'Sign out'
      }
    },    
    {
      path: '/forgot',
      name: 'recoverPassword',
      component: () => import(/* webpackChunkName: "home" */ '@/views/RecoverPassword.vue'),
      meta: {
        redirectIfSignedIn: true,
        requiresAuth: false,
        title: 'Recover password'
      }
    },
    {
      path: '/reset',
      name: 'resetPassword',
      component: () => import(/* webpackChunkName: "home" */ '@/views/ChangePassword.vue'),
      meta: {
        redirectIfSignedIn: false,
        requiresAuth: false,
        title: 'Reset password'
      }
    },
    {
      path: '/account',
      name: 'account',
      component: () => import(/* webpackChunkName: "home" */ '@/views/Account.vue'),
      meta: {
        requiresAuth : true,
        title: 'Your account'
      }
    },        
    {
      path: '/apps/analyses',
      name: 'analyses',
      component: () => import(/* webpackChunkName: "analysis" */ '@/views/apps/analyses/Analyses.vue'),
      meta: {
        requiresAuth : true,
        title: 'Analyses'
      }
    },        
    {
      path: '/apps/analyses/:id',
      name: 'analysis',
      component: () => import(/* webpackChunkName: "analysis" */ '@/views/apps/analyses/Analysis.vue'),
      meta: {
        requiresAuth : true,
        title: 'Analysis'
      }
    },        
    {
      path: '/apps/analyses/:id/whatif',
      name: 'whatifs',
      component: () => import(/* webpackChunkName: "analysis" */ '@/views/apps/analyses/Whatifs.vue'),
      meta: {
        requiresAuth : true,
        title: 'What-ifs'
      }
    },        
    {
      path: '/apps/analyses/:id/whatif/:scenarioId',
      name: 'whatif',
      component: () => import(/* webpackChunkName: "analysis" */ '@/views/apps/analyses/Whatif.vue'),
      meta: {
        requiresAuth : true,
        title: 'What-if'
      }
    },        
    {
      path: '/apps/twins',
      name: 'twins',
      component: () => import(/* webpackChunkName: "marketing" */ '@/views/apps/twins/Twins.vue'),
      meta: {
        requiresAuth: true,
        title: 'Twins'
      }
    },  
    {
      path: '/apps/advisories',
      name: 'advisories',
      component: () => import(/* webpackChunkName: "marketing" */ '@/views/apps/advisories/Advisories.vue'),
      meta: {
        requiresAuth: true,
        title: 'Advisories'
      }
    },      
    {
      path: '/apps/library',
      name: 'library',
      component: () => import(/* webpackChunkName: "marketing" */ '@/views/apps/library/Library.vue'),
      meta: {
        requiresAuth: true,
        title: 'Library'
      }
    },      
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import(/* webpackChunkName: "marketing" */ '@/views/Dashboard.vue'),
      meta: {
        requiresAuth: true,
        title: 'Dashboard'
      }
    },    
    {
      path: '/mtbf',
      name: 'mtbf',
      component: () => import(/* webpackChunkName: "home" */ '@/views/Mtbf.vue'),
      meta: {
        requiresAuth: true,
        title: 'MTBF Worksheet'
      }
    },
    {
      path: '/support',
      name: 'support',
      component: () => import(/* webpackChunkName: "home" */ '@/views/Support.vue'),
      meta: {
        requiresAuth: true,
        title: 'Support'
      }
    },
    {
      path: '/notfound',
      name: 'notfound',
      component: () => import(/* webpackChunkName: "home" */ '@/views/NotFound.vue'),
      props: true,
      meta: {
        requiresAuth: false,
        title: 'Not Found'
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  store.dispatch('initialize')
  .then(() => {
    const loggedIn = store.getters.isLoggedIn;
    if (!to.matched.length) {
      next(`/notfound?invalidroute=${to.path}`)
    } else if (!loggedIn && to.matched.some(record => record.meta.requiresAuth)) {
      store.dispatch('routeAfterLogin', { path: to.path, params: to.params, query: to.query, name: to.name })
        .then(() => next('/signin') )      
    } else if (loggedIn && to.matched.some(record => record.meta.redirectIfSignedIn)) {
      Vue.$gtag.config({ user_id: Buffer.from(store.getters.currentUser.email.toLowerCase()).toString('base64') })
      next('/home')
    } else {
      if(loggedIn)
        Vue.$gtag.config({ user_id: Buffer.from(store.getters.currentUser.email.toLowerCase()).toString('base64') })        
      next()
    }
  })
})

router.afterEach((to) => {
  Vue.nextTick(() => document.title = to.meta.title ? to.meta.title + ` - ${appName}` : appName)
})

export function handleRouterError(error) {
  if (!isNavigationFailure(error, NavigationFailureType.aborted)) {
    throw error
  }
}

export default router
